<template>
  <div class="parallax_container">
    <div class="parallax" id="parallax">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    parallax: {
      default: true,
      type: Boolean
    },
    onScreen: {
      default: true,
      type: Boolean
    },
    minMax: {
      default: true,
      type: Boolean
    },
    speed: {
      default: 0.15,
      type: Number
    },
    autosize: {
      type: Boolean,
      default: false
    },
    reverse: {
      default: false,
      type: Boolean
    },
    masked: {
      default: false,
      type: Boolean
    },
    parallaxType: {
      type: String,
      default: "Offset",
      validator: function(value) {
        return ["Default", "Offset", "Image"].indexOf(value) !== -1;
      }
    },
    marged: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: "Vertical",
      validator: function(value) {
        return ["Horizontal", "Vertical"].indexOf(value) !== -1;
      }
    },
    skewed: {
      type: Boolean,
      required: false
    },
    rotated: {
      type: Boolean,
      required: false
    },
    degree: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: mapState(["offsetTop"]),
  watch: {
    offsetTop() {
      this.move();
    }
  },
  data() {
    return {
      element: null
    };
  },
  created() {},
  mounted() {
    this.element = this.$el.querySelector("#parallax");
    //this.$el.style.width = this.element.style.width =this.$el.getBoundingClientRect().width + "px";
    setTimeout(
      function() {
        this.$el.style.height =
          this.element.getBoundingClientRect().height + "px";
      }.bind(this),
      250
    );
    /* setTimeout(
      function() {
        this.$el.style.height =
          this.element.getBoundingClientRect().height + "px";
      }.bind(this),
      500
    ); */
    this.element.classList.add(this.parallaxType);
    this.$el.classList.add(this.parallaxType);

    if (this.marged) this.$el.style.marginTop = this.$el.offsetTop + "px";
    if (this.skewed) this.$el.style.transform = `skewY(${this.degree}deg)`;
    if (this.masked) this.$el.classList.add("masked");
    this.move();
  },
  methods: {
    move: function() {
      this.$el.style.height =
        this.element.getBoundingClientRect().height + "px";
      let transform = {
        x: 0,
        y: 0,
        skew: 0,
        rotate: 0
      };
      if (this.direction === "Vertical")
        if (this.reverse) transform.y = this.offsetTop * this.speed;
        else transform.y = this.offsetTop * -this.speed;
      else if (this.direction === "Horizontal")
        if (this.reverse) transform.x = this.offsetTop * this.speed;
        else transform.x = this.offsetTop * -this.speed;

      if (this.marged) transform.y += this.$el.offsetTop;
      if (this.skewed) transform.skew = -this.degree;
      if (this.rotated) transform.rotate = -this.degree;
      this.element.style.transformOrigin = "center center";
      this.element.style.transform = `translate(${transform.x}px, ${transform.y}px) skewY(${transform.skew}deg) rotate(${transform.rotate}deg)`;
    }
  }
};
</script>
<style scoped>
.parallax_container {
  position: relative;
}
.masked {
  overflow: hidden;
}
.parallax {
  width: 100%;
}
.parallax.Offset {
  position: absolute;
}
.parallax_container.Image {
  overflow: hidden;
  position: absolute;
}
</style>