<template>
  <div align="center" class="soutien" style="padding:20px;">
    <v-row align="center" justify="center" class="article_content">
      <v-card pa="20" align="center" justify="center" style="text-align:center;" elevation="0">
        <h3 class="styloText" style="text-align:center; margin-bottom:0;">Contacter la Bige !</h3>
        <v-card-text style="text-align:center;">
          Décris ton projet et prend rendez-vous en ligne,
          <br />je serais toujours opé pour une bière ou un café !
        </v-card-text>
        <v-card-actions style="text-align:center;">
          <v-btn
            class="primary"
            to="/contact/create"
            style="left:0; right:0; margin:auto;"
          >Prendre rendez-vous !</v-btn>
        </v-card-actions>
        <br />
      </v-card>
    </v-row>
  </div>
</template>
<script>
export default {};
</script>