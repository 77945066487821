<template>
  <div class="onscreen_container">
    <div class="onscreen" id="onscreen">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    delay: {
      type: Number,
      default: 1,
    },
    moveY: {
      type: Number,
      default: 71,
    },
  },
  computed: mapState(["offsetTop"]),
  watch: {
    offsetTop() {
      this.move();
    },
  },
  data() {
    return {
      element: null,
    };
  },
  mounted() {
    this.element = this.$el.querySelector("#onscreen");
    this.$el.style.height = this.element.style.height =
      this.$el.getBoundingClientRect().height + "px";
    this.move();
    setTimeout(
      function () {
        this.move();
      }.bind(this),
      200
    );
  },
  methods: {
    move: function () {
      if (
        this.offsetTop + window.innerHeight - window.innerHeight / 3 >
        this.$el.offsetTop + this.moveY
      ) {
        //  setTimeout(() => {
        this.element.style.transform = `translate(0px, ${this.moveY}px) translateZ(0)`;
        this.element.style.opacity = 1;
        // }, this.delay);
      } else {
        this.element.style.transform = `translate(0px, ${
          this.moveY + 200
        }px) translateZ(0)`;
        this.element.style.opacity = 0;
      }
      /* this.element.style.transformOrigin = "center center";
      this.element.style.transform = `translate(0px, 0px))`;
      this.element.style.opacity = 1; */
    },
  },
};
</script>
<style scoped>
.onscreen_container {
  position: relative;
}
.onscreen {
  width: 100%;
  transition: all 0.8s ease-out;
  transform: translate(0px, 400px);
  opacity: 0;
}
</style>