<template>
  <div>
    <div class="articles_list_tabs" style="position:fixed; z-index:4;">
      <div class="tab_nav_container">
        <v-tabs style="width:100%; max-width:980px;" :fixed-tabs="fixedTab">
          <v-tab to="/">À propos</v-tab>
          <v-tab to="/about/ateliers">Ateliers | UX</v-tab>
          <v-tab to="/about/uiux">Création | UI</v-tab>
          <v-tab to="/about/developpement">Développement</v-tab>
          <v-tab to="/about/motion">Motion</v-tab>
        </v-tabs>
      </div>
    </div>
    <!-- <br />
    <br />
    <h2>Page {{ JSON.stringify($route.params.page) }}</h2>-->
    <div
      style="position:fixed;left:0;right:0;margin:auto;bottom:0;width:80px;height:80px;z-index:9;"
    >
      <lottie
        :options="scrollMotion"
        v-on:animCreated="handleAnimation"
        width="80"
        height="80"
        type="sized"
        :loop="true"
        style="margin:0;"
        :dynamic="false"
        :states="2"
        :state="0"
        ratio="xMidYMid meet"
        v-if="$store.state.offsetTop === 0"
      />
    </div>
    <router-view></router-view>
    <Onscreen :moveY="((isMobile)?0:-0)" :delay="500">
      <ContactFooter></ContactFooter>
    </Onscreen>
  </div>
</template>

<script>
import Parallax from "@/components/UI/Parallax";
import Onscreen from "@/components/UI/OnScreen";
import ContactFooter from "@/components/Contact/ContactFooter";
import * as ScrollMotion from "@/assets/motions/UI/scrollMotion.json";
const Lottie = () => import("@/components/LottieMotion.vue");

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Parallax, Onscreen, Lottie, ContactFooter },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fixedTab: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      },
    },
  },
  data() {
    return {
      scrollMotion: {
        animationData: ScrollMotion.default,
        loop: true,
        autoplay: true,
      },
    };
  },
  methods: {
    handleAnimation: function () {},
  },
};
</script>
<style >
.about {
  padding-top: 48px;
}
.about .header {
  /* height: 40vh; */
  position: relative;
  overflow: hidden;
}
</style>